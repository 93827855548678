<template>
    <div>
        <div class="d-flex justify-content-center">
            <b-spinner large v-if="loading" class="mx-2" />
            <span v-if="loading">Loading...</span>
        </div>
        <b-form @submit.prevent="editRawMaterial">
            <b-row>
                <b-col md="12">
                    <b-form-group label="Raw Material Name" label-for="mc-Raw Material-Supplier">
                        <b-form-input id="mc-Raw Material-Supplier" placeholder="Raw Material" v-model="rawmaterialsID.name"
                            readonly />
                    </b-form-group>
                </b-col>
                <b-col md="6">
                    <b-form-group label="Raw Material Code" label-for="mc-Raw Material-Code">
                        <b-form-input id="mc-Raw Material-Code" v-model="rawmaterialsID.code" readonly />
                    </b-form-group>
                </b-col>
                <b-col md="6">
                    <b-form-group label-for="mc-stock-threshold" label="Low Stock Threshold">
                        <div class="form-label-group">
                            <b-form-input id="mc-stock" type="text" placeholder="Low Stock Threshold"
                                v-model="rawmaterialsID.lowStock" readonly />
                        </div>
                    </b-form-group>
                </b-col>
                <b-col cols="6">
                    <b-form-group label="Category" label-for="mc-Category" v-if="rawmaterialsID.category">
                        <b-form-input v-model="rawmaterialsID.category.name" placeholder="RMTest1" readonly />
                    </b-form-group>
                </b-col>
                <b-col cols="6">
                    <b-form-group label="UOM" label-for="mc-Uom">
                        <b-form-input v-model="uomName" placeholder="Kg" readonly />
                    </b-form-group>

                </b-col>
                <b-col md="6">
                    <b-form-group label="Price (Rs)" label-for="Price (Rs)">
                        <b-form-input type="text" id="mc-Raw Material-Price" placeholder="Raw Material Price"
                            v-model="rawmaterialsID.purchasePrice" readonly />
                    </b-form-group>
                </b-col>
                <b-col cols="6">
                    <b-form-group label="Preferred Supplier" label-for="mc-Supplier">

                        <b-form-select v-model="rawmaterialsID.supplierId" :options="suppliers" value-field="id"
                            text-field="name" disabled />
                    </b-form-group>
                </b-col>
                <b-col cols="12" class="mb-2">
                    <label for="textarea-default">Raw Material Description</label>
                    <b-form-textarea id="textarea-default" rows="5" placeholder="Description"
                        v-model="rawmaterialsID.description" readonly />
                </b-col>
                <!-- submit and reset -->
            </b-row>
        </b-form>
    </div>
</template>  

<script>
import { BRow, BCol, BForm, BFormInput, BFormTextarea, BFormSelect, BFormDatepicker, BFormGroup, BButton, BSpinner } from 'bootstrap-vue'
import { mapActions, mapGetters } from "vuex";
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'

export default {
    name: 'EditRawMaterial',
    components: {
        BRow,
        BCol,
        BForm,
        BFormInput,
        BFormTextarea,
        BFormSelect,
        BFormDatepicker,
        BFormGroup,
        BButton,
        ValidationProvider,
        ValidationObserver,
        BSpinner,
        ///
        required,

    },
    props: {
        rawmaterial: {
            type: Object,
            default: () => { }
        }
    },
    data() {
        return {
            id: this.$route.params.id,
            uomName: ''
        }
    },
    computed: {
        ...mapGetters("rawmaterialModule", {
            rawmaterialsID: "rawmaterialsID",
            loading: "loading"
        }),
        ...mapGetters("itemUomModule", {
            itemUoms: "itemuom",
            loading: "loading"
        }),
        ...mapGetters("supplierModule", {
            suppliers: "suppliers",
            loading: "loading"
        })

    },
    methods: {
        ...mapActions("supplierModule", [
            "getSupplierListAction"
        ]),
        success() {
            this.$swal({
                title: 'RawMaterial updated!',
                text: 'You have successfully edited the RawMaterial!',
                icon: 'success',
                customClass: {
                    confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
            })
        },
        error() {
            this.$swal({
                title: 'Error!',
                text: ' Failed!',
                icon: 'error',
                customClass: {
                    confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
            })
        },
        ...mapActions("rawmaterialModule", [
            "getRawMaterialListByIdAction"
        ]),

        ...mapActions("itemUomModule", [
            "getItemUOMListAction"
        ]),
        editRawMaterial() {
            let payload = {
                id: this.id,
                name: this.name,
                description: this.description,
                categoryId: this.categoryId,
                code: this.code,
                lowStock: this.lowStock,
                defaultUomID: this.defaultUomID,
                purchasePrice: this.purchasePrice,
            }
            this.updateRawMaterialAction(payload)
                .then(() => {
                    this.success()
                })
                .catch(() => {
                    this.error()
                })
        }
    },
    async mounted() {
        await this.getItemUOMListAction();
        await this.getRawMaterialListByIdAction(this.id);
        await this.getSupplierListAction();

        let uom = this.itemUoms.filter(obj => {
            return obj.id === this.rawmaterialsID.defaultUomID;
        });

        if (uom.length > 0) {
            this.uomName = uom[0].name;
        }
    }
}
</script>


